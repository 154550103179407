import React, { useState, useEffect } from "react"
import client from '../api/contentful'

import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "../components/Container"

import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import options from '../components/linkOptions'

export const query = graphql`
query updateQuery($slug: String!) {
    contentfulUpdate(slug: { eq: $slug }) {
        slug
    }
}
`

const Update = ({data}) => {
    const doc = data.contentfulUpdate;
    const[content, setContent] = useState();

    useEffect(() => {
        client.getEntries({
            content_type: 'update',
            'fields.slug[in]': doc.slug,
        })
        .then(entry => setContent(entry.items[0]))
        .catch(err => console.log(err));
    }, [doc.slug])

    if (!doc || !content ) return null;
    console.log(content)

    

    const formatDate = (date) => {
        const newDate = new Intl.DateTimeFormat('en-US', {year: 'numeric', month: 'long', day: 'numeric' }).format(Date.parse(date))
        return newDate;
    }
            
    return (
        <Layout>
            <SEO title={formatDate(content.fields.date)} />
            
            <Container>
            <section className="page-content col update-template">
                

                <div className="row">
                    <div className="col-12">
                        

                        <section className="hero">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12 offset-lg-1">
                                        <h1 className="hero-title">{content.fields.title}</h1>
                                        {content.fields.date && <div className="hero-description">{formatDate(content.fields.date)}</div>}
                                        {/* {doc.description && <div className="hero-description">{doc.description.description}</div>} */}
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>

                    <div className="col-lg-12">
                        
                    </div>

                    <div className="col-lg-9 richtext">
                        {content.fields.content && documentToReactComponents(content.fields.content, options)}
                    </div>
                </div>
            </section>
            </Container>
        </Layout>
    )
}

export default Update
